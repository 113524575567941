<template>
  <div class="studyContent">
    <div class="leftContent">
      <div :class="topClass">
        <span>本节时长：<i class="el-icon-alishijian"></i>{{getHMS(currTime)}}</span>
        <span>总时长：<i class="el-icon-alishijian"></i>{{getHMS(useTime)}}</span>
      </div>
      <div class="contentBox" v-if="videoType == 1">
        <iframe :src="studyRoute" frameborder="0" style="width:100%;height:100%"></iframe>
      </div>

      <div class="videoBox" v-if="videoType == 2">
        <iframe ref="iframeRef" name="iframeRef" id="iframeRef" :src="studyRoute" frameborder="0" style="width:100%;height:100%"></iframe>
      </div>

      <div class="cardBox" v-if="videoType == 3">
        <div class="cardTitle">知识点测验</div>
        <div class="cardProcess">当前题目：{{cardIndex + 1}}/{{cardList.length}}</div>
        <div class="cardInfo">
          <p>{{cardData.Title}}</p>
          <p>{{cardData.Analysis}}</p>
        </div>
        <ul class="btnList">
          <li v-for="(item,index) in cardBtn" :key="index" @click="clickBtn(item)">
            {{item}}
          </li>
        </ul>
        <div class="shoadwBox" v-if="shoadwType">
          <div class="shoadwInfo">
            <p>你已完成 知识卡片 自测！</p>
            <div class="shoadwBtnList">
              <div class="shoadwBtn" @click="cardAgain">
                <i class="el-icon-alishuaxin"></i>
                <p>重新自测</p>
              </div>
              <div class="shoadwBtn" @click="cardComplete">
                <i class="el-icon-aliwancheng"></i>
                <p>确认完成</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightNav">
      <div @click="clickPoint">
        <i class="el-icon-alicaidan2" style="font-size:40px"></i>
      </div>
      <div>
        <div @click="clickTopSwitch">
          <i class="el-icon-alishangyishou" style="font-size:40px;margin-bottom:10px"></i>
        </div>
        <div class="midBox" @click="clickStudy">
          <p>{{studyTitle}}</p>
        </div>
        <div @click="clickBottomSwitch" v-if="!isComplete">
          <i class="el-icon-alixiayishou" style="font-size:40px;margin-top:10px"></i>
        </div>
        <div @click="complete" v-if="isComplete">
          <i class="el-icon-aliwancheng"></i>
        </div>
      </div>
      <div @click="back">
        <i class="el-icon-alifanhui" style="font-size:30px"></i>
      </div>
    </div>

    <!-- 知识点盒子 -->
    <div class="studyPoint" v-show="pointType" >
      <div>胸腔穿刺术：{{completeNum}}/{{pointList.length}}</div>
      <ul class="pointUl">
        <li v-for="(item,index) in pointList" @click="clickpoint(item,index)" :key="index" :class="{active:ins == index}">
          <span style="width:150px">{{item.Title}}</span>
          <span v-if="item.State">已完成</span>
        </li>
      </ul>
    </div>

    <!-- 学习资料盒子 -->
    <ul class="studyData" v-show="studyType">
      <li v-for="(item,index) in studyList" :key="index" @click="clickStudyList(item,index)">
        {{item.Sort}}. {{item.Title}}
      </li>
    </ul>

    <!-- 跳转上一个/下一个前的弹出框 -->
    <div class="dialogBox" v-if="dialogShow">
      <div class="dialogMain">
        <p class="dialogTitle">你已完成 {{demoTitle}} 的学习！</p>
        <div class="dialogBtn">
          <div class="leftBtn" @click="again">
            <i class="el-icon-alishuaxin"></i>
            <p>重新学习</p>
          </div>
          <div class="rightBtn" @click="goNext" v-if="!isComplete">
            <i class="el-icon-alixiayishou" style="font-size:30px;"></i>
            <p>进入下一知识点</p>
          </div>
          <div class="rightBtn" @click="complete" v-if="isComplete">
            <i class="el-icon-aliwancheng"></i>
            <p>完成学习</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 完成列表显示 -->
    <div class="completeBox" v-if="isCompleteBox">
      <div class="completeMain">
        <div class="completeTitle">
          此次学习用时{{getHMS(currTime)}}，再接再厉！
        </div>
        <ul>
          <li v-for="(item,index) in pointList" :key="index">
            <span>{{item.Title}}</span>
            <!-- <span>{{item.time}}</span> -->
          </li>
        </ul>
        <div class="completeBtn" @click="backStudy">退出学习</div>
        <img src="../../../assets/img/doctor.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { Notify } from 'vant';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      // 移动端视屏大小
      bodyWidth: "",
      bodyHeight: "",

      iframeWin: "", // iframeRef
      demoId: "", // 项目ID
      demoTitle: "",//项目名称
      topClass: "topTips", // 顶部计时条class名

      // 知识点数据
      pointList: [],
      ins: 0,
      pointType: false,
      completeNum: 0,

      // 学习资料数据
      studyList: [],
      studyTitle: "",
      studyIndex: 0,
      studyType: false,
      studyResourceType: "",
      studyRoute: "",

      videoType: 0,// 视频显示/文字显示
      videoEnd:false,

      dialogShow: false, // 是否跳转上一个/下一个知识点

      isComplete: false, // 是否完成练习
      isCompleteBox: false, // 是否打开完成训练
      currTime: 0, // 进入页面的时间
      useTime: 0, // 总时长
      currentTimer: "", // 计时器
      cardList: [],
      cardData: {},
      cardIndex: 0,
      cardBtn: ["掌握", "模棱两可", "未掌握"],
      shoadwType: false,
      studentID: "",
      projectID: "",
      StudentGroupID:"",//分组ID
    }
  },
  methods: {
    // 点击知识点 弹出框
    clickPoint() {
      this.pointType = !this.pointType;
      this.studyType = false;
    },
    // 点击学习资料打开/关闭弹出框
    clickStudy() {
      this.studyType = !this.studyType;
      this.pointType = false;
    },
    // 点击学习资料弹出框标题
    clickStudyList(item, index) {
      console.log(item)
      this.studyTitle = item.Title;
      this.studyIndex = index;
      this.studyResourceType = item.ResourceType;
      if (this.studyResourceType == "视频") {
        this.videoType = 1;
        this.topClass = "topTips2";
        this.studyRoute = item.Route;
      } else if (this.studyResourceType == "文件") {
        this.videoType = 2;
        this.topClass = "topTips";
        this.studyRoute = item.Route;
      } else if (this.studyResourceType == "知识卡片") {
        this.videoType = 3;
        this.topClass = "topTips";
        this.cardList = item.KnowledgeCardList;
        this.cardData = item.KnowledgeCardList[this.cardIndex];
      }
      this.studyType = false;
    },
    //点击知识点列表的知识点选项
    clickpoint(item, index) {
        if(item.State)
        {
          this.cookies.kkSet("demoIndex", index);
          this.$router.push({ name: "videoStudy" });
        }
     },
    // 点击上一个知识点
    clickTopSwitch() {
      if (this.studyIndex > 0) {
         this.videoEnd=false;
        this.studyIndex--
        this.studyTitle = this.studyList[this.studyIndex].Title;
        this.studyResourceType = this.studyList[this.studyIndex].ResourceType;
        this.studyRoute = this.studyList[this.studyIndex].Route;
        if (this.studyResourceType == "视频") {
          this.videoType = 1;
          this.topClass = "topTips2";
        } else if (this.studyResourceType == "文件") {
          this.videoType = 2;
          this.topClass = "topTips";
          this.studyRoute = this.studyList[this.studyIndex].Route;
        } else if (this.studyResourceType == "知识卡片") {
          this.videoType = 3;
          this.topClass = "topTips";
          this.cardList = this.studyList[this.studyIndex].KnowledgeCardList;
          this.cardData = this.studyList[this.studyIndex].KnowledgeCardList[this.cardIndex];
        }
      } else {
        Notify({ type: 'warning', message: '已经是第一个！' });
      }
    },
    // 点击下一个知识点
    clickBottomSwitch() {
      console.log(this.videoEnd);
      if(this.videoEnd == false)
      {
         this.$dialog({ message: "请学习完当前内容后在继续操作" });
        return;
      }

      if (this.studyIndex <= this.studyList.length - 2) {
        this.videoEnd=false;
        this.studyIndex++
        this.studyTitle = this.studyList[this.studyIndex].Title;
        this.studyResourceType = this.studyList[this.studyIndex].ResourceType;
        this.studyRoute = this.studyList[this.studyIndex].Route;
        if (this.studyResourceType == "视频") {
          this.videoType = 1;
          this.topClass = "topTips2";
        } else if (this.studyResourceType == "文件") {
          this.videoType = 2;
          this.topClass = "topTips";
        } else if (this.studyResourceType == "知识卡片") {
          this.videoType = 3;
          this.topClass = "topTips";
          this.cardList = this.studyList[this.studyIndex].KnowledgeCardList;
          this.cardData = this.studyList[this.studyIndex].KnowledgeCardList[this.cardIndex];
        }
      } else if (this.studyIndex > this.studyList.length - 2) {
        this.dialogShow = true;
      }
    },

    // 点击重新学习
    again() {
      this.dialogShow = false;
    },
    // 点击进入下一知识点
    goNext() {
      if (this.ins < this.pointList.length - 1) {
        var obj = {
          StudentID: this.studentID,
          LearningProjectID: this.projectID,
          StudentGroupID:this.StudentGroupID,
          KnowledgePointsID: this.demoId,
          Spendtime: this.currTime
        };
        this.$axios.post('/api/KnowledgePoints/InsertLearningRecords', obj).then(res => {
          console.log(res);
          this.cookies.kkSet("demoIndex", this.ins + 1);
          this.$router.push({ name: "videoStudy" });
        });
      }
    },
    // 点击完成练习
    complete() {
       if(this.videoEnd == false)
      {
         this.$dialog({ message: "请学习完当前内容后在继续操作" });
        return;
      }

        var obj = {
            StudentID: this.studentID,
            LearningProjectID: this.projectID,
            StudentGroupID:this.StudentGroupID,
            KnowledgePointsID: this.demoId,
            Spendtime: this.currTime
          };
          this.$axios.post('/api/KnowledgePoints/InsertLearningRecords', obj).then(res => {
            console.log(res);
          });  

      this.isCompleteBox = true;
      clearInterval(this.currentTimer);
    },
    // 点击返回
    back() {
      this.$router.push({ name: "videoStudy" });

    },
    // 点击退出学习
    backStudy() {
      this.$router.push({ name: "home" });
    },
    // 页面接口渲染
    initData() {
      this.$axios.get('/api/ContentResources/GetList?vKnowledgePointsID=' + this.demoId, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data)
        this.studyList = data.data;
        this.studyTitle = this.studyList[this.studyIndex].Title;
        this.studyResourceType = this.studyList[this.studyIndex].ResourceType;
        this.studyRoute = this.studyList[this.studyIndex].Route;
        if (this.studyResourceType == "视频") {
          this.videoType = 1;
          this.topClass = "topTips2";
          this.$nextTick(() => {
            //this.$refs.iframeRef.contentWindow.postMessage(this.studyRoute, '*');
            console.log(this.studyRoute);
          });
        } else if (this.studyResourceType == "文件") {
          this.videoType = 2;
          this.topClass = "topTips";
        } else if (this.studyResourceType == "知识卡片") {
          this.videoType = 3;
          this.topClass = "topTips";
        }
      });

      var obj2 = {
        StudentID: this.studentID,
        LearningProjectID: this.projectID,
        StudentGroupID: this.StudentGroupID,
      };
      this.$axios.post('/api/KnowledgePoints/GetList', obj2).then(res => {
        var data = JSON.parse(res.data);
        this.pointList = data.data;
        if (this.ins == this.pointList.length - 1) {
          this.isComplete = true;
        } else {
          this.isComplete = false;
        }
        this.pointList.forEach(v => {
          if (v.State) {
            this.completeNum++;
          }
        })
      });
    },
    // 点击知识卡片下面按钮进入下一题
    clickBtn(item) {
      let para = {
        StudentID: this.studentID,
        KnowledgeCardID: this.cardList[this.cardIndex].ID,
        StudentGroupID:this.StudentGroupID,
        Answer: item,
      };
      if (this.cardList.length - 1 != this.cardIndex) {
        this.cardIndex++;
      } else {
        this.shoadwType = true;
      }
      if (this.cardList.length > this.cardIndex) {
        this.$axios.post('/api/ContentResources/InsertKnowledgeCardLog', para).then(res => {
          if (JSON.parse(res.data).code == 200) {
            this.cardData = this.cardList[this.cardIndex];
          }
        });
      }
    },
    // 点击知识卡片重新自测
    cardAgain() {
      this.shoadwType = false;
      this.cardIndex = 0;
      this.cardData = this.cardList[this.cardIndex];
    },
    // 点击知识卡片确认完成
    cardComplete() {
      this.shoadwType = false;
    },
    // 页面渲染修改样式
    domCss() {
      this.bodyWidth = document.body.clientWidth;
      this.bodyHeight = document.body.clientHeight;
      $(".studyContent ").css({ width: this.bodyHeight });
      $(".studyContent ").css({ height: this.bodyWidth });
      $(".leftContent").css({ height: this.bodyWidth });
      $(".rightNav").css({ height: this.bodyWidth });
      $(".studyPoint").css({ height: this.bodyWidth });
      $(".pointUl").css({ height: this.bodyWidth - 40 });
      $(".studyData").css({ height: this.bodyWidth });
    },
    // 转换秒数
    getHMS(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime >= 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
          hourTime = parseInt(minuteTime / 60);
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime)) + "秒";
      var result2 = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + "分" + result;
      var result3 = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + "时" + result2;
      if (parseInt(hourTime) == 0) {
        return result2;
      } else {
        return result3;
      }
    },
  },
  mounted() {
    this.demoId = this.cookies.kkGet("demoId");
    this.ins = this.cookies.kkGet("demoIndex");
    this.demoTitle = this.cookies.kkGet("demoTitle");
    this.studentID = Cookies.get('studentID');
    this.projectID = this.cookies.kkGet("projectID");
    this.StudentGroupID=this.cookies.kkGet("StudentGroupID");
    this.domCss();
    this.initData();
    this.useTime = this.cookies.kkGet("useTime");
    this.currentTimer = setInterval(() => {
      this.currTime++;
      this.useTime++;
    }, 1000);
    window.addEventListener('message',event=>{
        let data =event.data; 
         console.log(data);
        if(data.isEnd=="1")
        {
          this.videoEnd=true;
        }
    })
  },
  // 离开页面函数
  destroyed() {
    clearInterval(this.currentTimer);
    this.currTime = 0;
    this.cookies.kkSet("useTime", this.useTime);
  }
}
</script>

<style scoped>
.studyContent {
  background: #fff;
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* 左侧内容样式 */
.studyContent .leftContent {
  width: calc(100% - 86px);
}
.studyContent .leftContent .videoBox {
  width: 100%;
  height: 100%;
  background: #000;
}
.studyContent .leftContent .contentBox {
  width: 100%;
  height: 100%;
}
.studyContent .leftContent .cardBox {
  width: 100%;
  height: 100%;
  padding: 30px 60px;
  position: relative;
}
.studyContent .leftContent .cardBox .cardTitle {
  width: 100%;
  font-size: 18px;
  text-align: center;
}
.studyContent .leftContent .cardBox .cardProcess {
  color: #727272;
}
.studyContent .leftContent .cardBox .cardInfo {
  margin-left: 30px;
  margin-top: 20px;
  font-size: 16px;
}
.studyContent .leftContent .cardBox .cardInfo p:first-child {
  color: #101010;
}
.studyContent .leftContent .cardBox .cardInfo p:last-child {
  text-indent: 2em;
  margin-top: 20px;
  line-height: 30px;
  height: 150px;
  overflow-y: auto;
}
.studyContent .leftContent .cardBox .btnList {
  width: calc(100% - 86px);
  height: 40px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}
.studyContent .leftContent .cardBox .btnList li {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  margin: 0 10px;
}
.studyContent .leftContent .cardBox .btnList li:nth-child(1) {
  background: #ea8a6a;
}
.studyContent .leftContent .cardBox .btnList li:nth-child(2) {
  background: #eac26a;
}
.studyContent .leftContent .cardBox .btnList li:nth-child(3) {
  background: #7fdb62;
}
.studyContent .leftContent .cardBox .shoadwBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}
.studyContent .leftContent .cardBox .shoadwBox .shoadwInfo {
  width: 400px;
  height: 180px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
  margin: 0 auto;
  background: #fff;
  padding: 40px 40px 0;
}
.studyContent .leftContent .cardBox .shoadwBox .shoadwInfo p {
  text-align: center;
  font-size: 18px;
}
.studyContent .leftContent .cardBox .shoadwBox .shoadwInfo .shoadwBtnList {
  display: flex;
  justify-content: center;
}
.studyContent .leftContent .cardBox .shoadwBox .shoadwInfo .shoadwBtn {
  width: 120px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 100);
  text-align: center;
  margin: 20px 10px;
}
.studyContent .leftContent .cardBox .shoadwBox .shoadwInfo .shoadwBtn i {
  font-size: 20px;
  margin: 5px 0;
}
.studyContent .shoadwBox .shoadwInfo .shoadwBtn:last-child {
  background: #26d8a2;
  color: #fff;
}

.studyContent .topTips {
  height: 26px;
  line-height: 26px;
  color: #727272;
  position: absolute;
  top: 0;
  right: 100px;
  background: #fff;
}
.studyContent .topTips span {
  margin: 15px;
}
.studyContent .topTips span i {
  margin-right: 5px;
  font-size: 13px;
}
.studyContent .topTips2 {
  height: 26px;
  line-height: 26px;
  color: #727272;
  position: absolute;
  top: 0;
  right: 100px;
  background: #d6d6d6;
}
.studyContent .topTips2 span {
  margin: 15px;
}
.studyContent .topTips2 span i {
  margin-right: 5px;
  font-size: 13px;
}

/* 右侧导航样式 */
.studyContent .rightNav {
  width: 86px;
  background: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}
.studyContent .rightNav div {
  color: #fff;
  text-align: center;
}
.studyContent .rightNav div i {
  font-size: 22px;
  margin: 2px 0;
}
.studyContent .rightNav div .el-icon-alishangyishou {
  font-size: 30px;
}
.studyContent .rightNav div .el-icon-alixiayishou {
  font-size: 30px;
}
.studyContent .rightNav .midBox {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  text-align: center;
  border: 1px solid #fff;
  margin-left: 5%;
  overflow: hidden;
}
.studyContent .rightNav .midBox p {
  word-wrap: break-word;
}

/* 知识点盒子样式 */
.studyContent .studyPoint {
  width: 240px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 86px;
  color: #fff;
}
.studyContent .studyPoint div {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}
.studyContent .studyPoint .pointUl {
  overflow-y: scroll;
}
.studyContent .studyPoint .pointUl li {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.studyContent .studyPoint ul .active {
  color: #1684fc;
  border-right: 1px solid #1684fc;
}

/* 学习资料盒子样式 */
.studyContent .studyData {
  width: 200px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 86px;
  color: #fff;
  overflow-y: auto;
}
.studyContent .studyData li {
  margin: 15px 0;
  padding: 10px 20px;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
}

/* 跳转下一站/完成知识点盒子样式 */
.studyContent .dialogBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.studyContent .dialogBox .dialogMain {
  width: 60%;
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  margin: 10vh auto;
}
.studyContent .dialogBox .dialogMain .dialogTitle {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
.studyContent .dialogBox .dialogMain .dialogBtn {
  display: flex;
  justify-content: center;
}
.studyContent .dialogBox .dialogMain .dialogBtn div {
  padding: 10px 15px;
  margin: 30px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
}
.studyContent .dialogBox .dialogMain .dialogBtn div i {
  font-size: 24px;
}
.studyContent .dialogBox .dialogMain .dialogBtn div p {
  font-size: 16px;
  letter-spacing: 3px;
  margin-top: 5px;
}
.studyContent .dialogBox .dialogMain .dialogBtn .rightBtn {
  background: #26d8a2;
  color: #fff;
  border: none;
}

/* 完成训练盒子样式 */
.studyContent .completeBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.studyContent .completeBox .completeMain {
  width: 100%;
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  margin: 2vh auto;
  position: relative;
}
.studyContent .completeBox .completeTitle {
  font-size: 24px;
  text-align: center;
}
.studyContent .completeBox .completeMain ul {
  width: 300px;
  height: 200px;
  overflow-y: auto;
  margin: 20px auto;
}
.studyContent .completeBox .completeMain ul li {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.studyContent .completeBox .completeBtn {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background: #1684fc;
  letter-spacing: 3px;
  color: #fff;
  margin: 0 auto;
}
.studyContent .completeBox .completeMain img {
  position: absolute;
  bottom: 0;
  left: 4%;
}
</style>